
  
  .no-slots-container {
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    width: 350px;
    padding: 20px;
    padding-bottom: 100px;
    background-color: #fff;
    /* box-shadow: 0 4px 8px rgba(0,0,0,0.1); */
    /* outline: 1px solid red; */
    border-radius: 8px;
    margin: auto;
    margin-bottom: 10px;
  }

  .no-slots-container{
    margin-bottom: 200px;
  }
  .no-slots-container h1 {
    color: #121D2F;
    font-weight: 600;
    line-height: 26px;
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .no-slots-container p {
    color: #121D2F;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 20px;
  }
  
  .button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin: 5px;
    width: calc(100% - 20px);
  }
  
  .thep-button1 {
    background: linear-gradient(90deg, #FFAE7C 0%, #FE6C11 100%);
    border: none;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 500;
    color: white;
    margin: 5px;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .thep-button2 {
    background: linear-gradient(90deg, #a3a4a7 0%, #999797 100%);
    border: none;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 500;
    color: white;
    margin: 5px;
    border-radius: 8px;
    cursor: pointer;
  }
  .thep-button1,
  .thep-button2 {
    display: flex;
    width: 250px;
    align-items: center;
    justify-content: center;
    gap: 5px;
    /* Ensure buttons are consistent in width */
  }
  .thep-buttons {
    display: flex;
    flex-direction: column;
    /* Stack buttons vertically */
    align-items: center;
    /* Center buttons horizontally */
    gap: 2px;
    /* Add space between buttons */
    width: 100%;
  }
  .button:disabled {
    background-color: rgba(165, 165, 165, 1);
  }
  
  
  @media (max-width: 800px) {
    .BookingContainer {
      width: 100%;
    }
  }
  