.popup {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255);
  padding: 20px;
  z-index: 1000;
  border-radius: 24px;
  width: 350px;
  text-align: center;
  /* Correct shadow for CSS */
  box-shadow: 0px 6px 20px 0px #2562CC2E;

}

.popup p {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 20px;
}

.popup-head {
  font-size: 24px;
  font-weight: 550;
  font-family: 'Open Sans', sans-serif;
  margin-top: 10px;
  margin-bottom: 10px;
}

.popup-button1 {
  background: linear-gradient(90deg, #FFAE7C 0%, #FE6C11 100%);
  border: none;
  padding: 8px 16px;
  font-size: 16px;
  color: white;
  margin: 5px;
  border-radius: 8px;
  cursor: pointer;
}

.popup-button2 {
  background: linear-gradient(90deg, #2878FF 0%, #2C65C8 100%);
  border: none;
  padding: 8px 16px;
  font-size: 16px;
  color: white;
  margin: 5px;
  border-radius: 8px;
  cursor: pointer;
}



.close-button {
  position: absolute;
  top: 12px;
  right: 12px;
  color: rgb(131, 125, 125);
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-buttons {
  display: flex;
  flex-direction: column;
  width: 208px;
  /* Stack buttons vertically */
  align-items: center;
  /* Center buttons horizontally */
  gap: 2px;
  /* Add space between buttons */
  width: 100%;
}

.popup-button1,
.popup-button2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  /* Ensure buttons are consistent in width */
}

.phone-icon {
  color: white;
  font-size: 15px;
  margin-right: 8px;
}

.close-icon {
  color: #B1B1B1;
  font-size: 15px;

}