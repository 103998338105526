.BookingContainer {
  position: relative;
  background-color: rgba(255, 255, 255, 0.8);
  height: 100vh;
  width: 30%;
  margin: auto;
  overflow: scroll;
}

.BookingWrapper {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  height: 100%;
}

.BookingHeading p {
  color: rgba(44, 55, 118, 1);
  font-size: 20px;
}

.AboutPhysica h1 {
  font-size: 20px;
  font-weight: 600;
}

.AboutPhysica p {
  font-size: 12px;
  font-weight: 400;
  margin-top: 12px;
  letter-spacing: 2%;
  line-height: 20px;
}

.AboutPhysica a {
  color: rgba(254, 130, 53, 1);
  font-size: 12px;
  cursor: pointer;
}

.ChooseTherapistWrapper {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  margin-top: 10px;
}

.ChooseTherapistContainer h1 {
  font-size: 20px;
  font-weight: 600;
}

.AllTherapist {
  padding: 8px 15px;
  border-radius: 5px;
  box-shadow: 0px 5px 30px 0px rgba(178, 178, 178, 0.2);
  cursor: pointer;
}

.AllTherapist p {
  text-align: center;
}

.CardWrapper {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 5px 30px 0px rgba(178, 178, 178, 0.2);
  min-width: 200px;
  cursor: pointer;
}

.CardWrapper h1 {
  font-size: 14px;
}

.CardWrapper p {
  font-size: 12px;
  text-align: center;
}

.TherapistCards {
  display: flex;
  align-items: center;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  gap: 15px;
  padding: 10px 2px;
}

.TherapistCards::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.TherapistCards {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.CardWrapper:hover {
  border: solid 2px rgba(254, 130, 53, 1);
}

.CardWrapper:hover.CardWrapper h1 {
  color: rgba(254, 130, 53, 1);
}

.CardWrapper:hover.CardWrapper p {
  color: rgba(254, 130, 53, 1);
}

.selectedCard {
  border: solid 2px rgba(254, 130, 53, 1);
  color: rgba(254, 130, 53, 1);
}

.timeSelectedCard {
  outline: solid 2px rgba(254, 130, 53, 1);
  color: rgba(254, 130, 53, 1);
}

.selectedCardDate {
  background-color: rgba(254, 130, 53, 1);
  color: white;
}
.SessionsDetails {
  padding: 10px 12px;
  border-radius: 10px;
  color: #2c3776;
  background-color: rgba(254, 243, 231, 1);
  display: flex;
  justify-content: space-between;
}
.FeesStructure {
  /* width: 25%; */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.FeesStructure h1 {
  color: rgba(254, 130, 53, 1);
  font-size: 16px;
  text-align: center;
}
.FeesStructure p {
  font-size: 14px;
}
.FeeImageStructure {
  display: flex;
  align-items: center;
  gap: 5px;
}

.DateCardWrapper {
  gap: 25px;
  margin-top: 10px;
  cursor: pointer;
}
.DateCardWrapper p {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}
.DateCardDetail {
  padding: 8px;
  border-radius: 5px;
  box-shadow: 0px 5px 30px 0px rgba(178, 178, 178, 0.2);
  min-width: 100px;
}
.DateCardDetail p {
  font-size: 12px;
  font-weight: 400;
}
.DateCardDetail h1 {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.DateCardWrapper:hover .DateCardDetail {
  background-color: rgba(254, 130, 53, 1);
  color: white;
}
.TimingHeading {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 100px;
}

.TimingHeading h1 {
  font-size: 20px;
  font-weight: 600;
}
.MorningDivHeading {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.MorningDivHeading p {
  font-size: 14px;
  font-weight: 600;
}
.TimeSlots {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.TimeSlotWrapper {
  padding: 8px;
  border-radius: 5px;
  box-shadow: 0px 5px 30px 0px rgba(178, 178, 178, 0.2);
  width: 22%;
  cursor: pointer;
}

.ProcessButton {
  position: fixed; /* Keeps the button fixed in the viewport */
  bottom: 20px; /* Positions the button at the bottom of the viewport */
  left: 0; /* Aligns the button to the left; adjust this as needed */
  width: 100%; /* Ensures the button container spans the full viewport width */
  text-align: center; /* Centers the button inside the container */
  padding-bottom: 10px;
}

.ProcessButton button {
  border: none;
  background-color: rgba(165, 165, 165, 1);
  color: white;
  padding: 5px 20px;
  border-radius: 10px;
}

.no-slots-container {
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  width: 270px;
  padding: 20px;
  padding-bottom: 100px;
  background-color: #fff;
  /* box-shadow: 0 4px 8px rgba(0,0,0,0.1); */
  /* outline: 1px solid red; */
  border-radius: 8px;
  margin: auto;
  margin-bottom: 10px;
}
.EmptyTimingHeading h1 {
  font-size: 20px;
  font-weight: 600;
}

.no-slots-container h1 {
  color: #121D2F;
  font-weight: 600;
  line-height: 26px;
  font-size: 20px;
  margin-bottom: 10px;
}

.no-slots-container p {
  color: #121D2F;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 20px;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin: 5px;
  width: calc(100% - 20px);
}

.popup-button1 {
  background: linear-gradient(90deg, #FFAE7C 0%, #FE6C11 100%);
  border: none;
  padding: 8px 16px;
  font-size: 16px;
  color: white;
  margin: 5px;
  border-radius: 8px;
  cursor: pointer;
}

.popup-button2 {
  background: linear-gradient(90deg, #2878FF 0%, #2C65C8 100%);
  border: none;
  padding: 8px 16px;
  font-size: 16px;
  color: white;
  margin: 5px;
  border-radius: 8px;
  cursor: pointer;
}
.popup-button1,
.popup-button2 {
  display: flex;
  width: 208px;
  align-items: center;
  justify-content: center;
  gap: 5px;
  /* Ensure buttons are consistent in width */
}
.popup-buttons {
  display: flex;
  flex-direction: column;
  /* Stack buttons vertically */
  align-items: center;
  /* Center buttons horizontally */
  gap: 2px;
  /* Add space between buttons */
  width: 100%;
}
.button:disabled {
  background-color: rgba(165, 165, 165, 1);
}


@media (max-width: 800px) {
  .BookingContainer {
    width: 100%;
  }
}
