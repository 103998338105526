/* Container */
.container {
    max-width: 450px;
    margin: auto;
    background-color: #f8f8f8;
    padding: 20px;
}

/* Status Bar */
.status-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
}

.status-icons {
    display: flex;
    gap: 5px;
}

.battery-container {
    display: flex;
    align-items: center;
}

.battery-text {
    margin-right: 2px;
}

/* Confirmation Header */
.confirmation-header {
    background-color: #ff9933;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-radius: 30px;
    color: white;
    text-align: center;
}

.confirmation-header h2 {
    font-size: 22px;
    font-weight: 700;
    line-height: 29.96px;
}
.checkmark-circle {
    background-color: #FFF;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}
.check-icon{
    size: 30px;
    color: #FE8235;
}

/* Content */
.content-container {
    padding: 20px;
}

/* Title */
.session-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 21.79px;
    margin-bottom: 15px;
    margin-top: 15px;
}

/* Details Row */
.details-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.detail-column {
    flex: 1;
}

.detail-label {
    color: #999;
    font-size: 14px;
    display: block;
}

.detail-value {
    font-size: 14px;
    font-weight: 600;
}

.detail-value1 {
    font-size: 16px;
    font-weight: 600;
}

/* Divider */
.divider {
    border: none;
    height: 1px;
    background-color: #ddd;
    margin: 15px 0;
}

/* Icon Detail Row */
.icon-detail-row {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
}

.icon {
    color: #ff9933;
    margin-right: 10px;
}

/* Message Box */
.message-container {
    background-color: #f2f2f2;
    padding: 15px;
    border-radius: 10px;
    margin-top: 20px;
    text-align: center;
}

.message-text {
    color: #666;
    font-size: 12px;
    line-height: 20px;
}

/* Appointment ID */
.appointment-id-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.appointment-id-label {
    color: #999;
    font-size: 14px;
}

.appointment-id-value {
    font-size: 18px;
    font-weight: 600;
}

/* Back Button */
.back-button {
    background-color: #ff9933;
    border-radius: 30px;
    padding: 12px;
    color: white;
    font-size: 16px;
    font-weight: 700;
    border: none;
    width: 100%;
    margin-top: 20px;
    cursor: pointer;
}

/* Navigation Bar */
.navigation-bar {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.home-indicator {
    width: 40px;
    height: 5px;
    background-color: #ddd;
    border-radius: 10px;
}
